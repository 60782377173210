<template>
  <div class="church-presentation-template">
    <page-body class>
      <section class="section-1 bg-block mb-4">
        <div class="bg-block p-4">
          <h4>{{ individualProfile.formal_name }}</h4>
          <h1 class="sm mb-4">
            {{ translations.tcEditLoginEmail }}
          </h1>
          <div class="body">
            <b-form>
              <b-form-group class="form-element">
                <div class="g-row">
                  <div class="g-col-1 d-flex">
                    <b-form-group class="form-element">
                      <h3 class="flex-2 mr-3 text-left font-style-2">{{ translations.tcLoginEmail }}</h3>
                      <b-form-input
                        id="txtLoginEmail"
                        v-model="login_email"
                        class="flex-0 mb-3 g-select"
                        type="text"
                        maxlength="75"
                        trim
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </b-form-group>
            </b-form>
          </div>
          <div class="body">
            <div class="d-flex">
              <b-button @click="handleSaveClick" variant="primary" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{
                translations.tcSave
              }}</b-button>
              <b-button @click="handleCancelClick" variant="tertiary" class="flex-0 w-100-sd mb-sm-0">{{
                translations.tcCancel
              }}</b-button>
            </div>
          </div>
        </div>
      </section>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'edit-member-login-email',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      login_email: '',
      login_ind_key: '',
    }
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      setSelectedEmail: 'user/setSelectedEmail',
      loadEmailFormOptions: 'membership/loadEmailFormOptions',
      saveLoginEmail: 'membership/saveLoginEmail',
      getIndividualCommPreferences: 'membership/getIndividualCommPreferences',
    }),
    async pageLoad() {
      this.setLoadingStatus(true)
      Promise.all([this.getViewTranslations()]).then(() => {
        this.setLoadingStatus(false)
        this.login_email = !(
          Object.keys(this.individualCommunicationPreferencesData.loginEmail).length === 0 &&
          this.individualCommunicationPreferencesData.loginEmail.constructor === Object
        )
          ? this.individualCommunicationPreferencesData.loginEmail.usr_login_id
          : ''
        this.login_ind_key = !(
          Object.keys(this.individualCommunicationPreferencesData.loginEmail).length === 0 &&
          this.individualCommunicationPreferencesData.loginEmail.constructor === Object
        )
          ? this.individualCommunicationPreferencesData.loginEmail.usr_ind_key
          : ''
      })
    },
    async handleSaveClick() {
      if (this.login_email === null) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcEmailRequired,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          return
        })
      } else {
        var payload = {
          usr_ind_key: this.login_ind_key,
          usr_login_id: this.login_email,
          usr_user: this.demograph.member_number,
          usr_delete_flag: false,
        }

        let result = true
        await Promise.all([
          this.setLoadingStatus(true),
          (result = await this.saveLoginEmail(payload)),
          this.getIndividualCommPreferences(this.userSelectedIndividualKey),
        ]).then(() => {
          this.setLoadingStatus(false)
          this.$swal({
            icon: result ? 'success' : 'error',
            text: result ? this.translations.tcEmailSaved : this.translations.tcErrorEmailNotSaved,
            confirmButtonText: this.translations.tcOk || 'Ok',
          }).then((result) => {
            if (result.value) {
              this.handleCancelClick()
            }
          })
        })
      }
    },
    async handleCancelClick() {
      this.$router.push({ name: 'user-preferences' })
    },
  },
  computed: {
    ...mapGetters({
      demograph: 'user/demograph',
      individualCommunicationPreferencesData: 'membership/individualCommunicationPreferencesData',
      individualProfile: 'membership/individualProfile',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
      userIndKey: 'user/userId',
      userName: 'user/userName',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
      userSelectedEmail: 'user/userSelectedEmail',
    }),
    optionsEmailTypes() {
      return [{ value: null, text: 'Select a type', disabled: true }]
    },
  },
  async created() {
    if (!this.userSelectedIndividualKey) {
      this.$router.push('/programs/mb/membership/')
    }
    await this.pageLoad()
  },
  components: {
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';

.page-body {
  padding: 80px 15px;
}
</style>
